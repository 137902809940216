export let SectorNames=["Industrials","Technology","Consumer Defensive","Consumer Cyclical",
  "Financial Services","Utilities", "Healthcare","Energy","Business Services",
  "Real Estate","Basic Materials","Other"];
export let SectorNums=[100,101,102,103,104,105,106,107,108,109,110,111];
export let IndustryNames=["Industrial Products","Business Services",
  "Engineering & Construction","Waste Management","Industrial Distribution","Airlines",
  "Consulting & Outsourcing","Aerospace & Defense","Farm & Construction Machinery",
  "Transportation & Logistics","Employment Services","Truck Manufacturing","Conglomerates",
  "Computer Hardware","Online Media","Application Software","Semiconductors",
  "Communication Equipment","Retail - Defensive","Consumer Packaged Goods","Tobacco Products",
  "Beverages - Alcoholic","Beverages - Non-Alcoholic","Education","Entertainment",
  "Retail - Apparel & Specialty","Restaurants","Manufacturing - Apparel & Furniture",
  "Autos","Advertising & Marketing Services","Homebuilding & Construction","Travel & Leisure",
  "Packaging & Containers","Personal Services","Publishing","Asset Management","Banks",
  "Brokers & Exchanges","Insurance - Life","Insurance","Insurance - Property & Casualty",
  "Credit Services","Insurance - Specialty","Utilities - Regulated",
  "Utilities - Independent Power Producers","Medical Diagnostics & Research","Biotechnology",
  "Medical Instruments & Equipment","Medical Devices","Drug Manufacturers","Health Care Plans",
  "Health Care Providers","Medical Distribution","Oil & Gas - Refining & Marketing",
  "Oil & Gas - E&P","Oil & Gas - Midstream","Oil & Gas - Services","Oil & Gas - Integrated",
  "Oil & Gas - Drilling","Communication Services","Consulting","REITs","Real Estate Services",
  "Chemicals","Forest Products","Agriculture","Metals & Mining",
  "Building Materials","Coal","Steel","Diversified Holdings"];
export let IndustryNums=[100001,100002,100003,100004,100005,100006,100007,100008,100009,
  100010,100011,100012,100013,101001,101002,101003,101004,101005,102001,102002,102003,
  102004,102005,102006,103001,103002,103003,103004,103005,103011,103013,103015,103018,
  103020,103026,104001,104002,104003,104004,104005,104006,104007,104013,105001,105002,
  106001,106002,106003,106004,106005,106006,106011,106014,107001,107002,107003,107004,
  107005,107006,108001,108002,109001,109002,110001,110002,110003,110004,110005,110006,
  110007,111001];
